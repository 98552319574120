var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { PageSplitterActionType } from "../actions";
import { PageSplitterState } from "./page-splitter.state";
/**
 * Начальное состояние.
 */
var initialState = new PageSplitterState();
/**
 * Обработчик событий, связанных с работой интерфейса расклейки страниц.
 *
 * @param state Состояние интерфейса расклейки страниц.
 * @param action Событие произошедшее в системе.
 */
export function pageSplitterReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var result = state;
    switch (action.type) {
        case PageSplitterActionType.DROP: {
            result = __assign({}, new PageSplitterState());
            break;
        }
        case PageSplitterActionType.INIT: {
            if (state.queueDocumentId !== (action.payload ? action.payload.id : null)) {
                if (!!action.payload.reviewedOperatorSplitting) {
                    result = __assign({}, prepareSplitting(action.payload, action.types));
                }
                else {
                    result = __assign({}, prepareDocument(action.payload));
                }
            }
            else {
                result = __assign({}, state, { sending: false, sent: false, error: null });
            }
            break;
        }
        case PageSplitterActionType.CUT: {
            var documents = updateDocuments(state);
            result = __assign({}, state, { documents: documents.concat([
                    {
                        id: documents.length,
                        pages: [],
                        type: {
                            name: "Не определён",
                            id: "NOT_EXIST",
                            extended: false,
                            foreign: null,
                            visibleOperatorHelpBadges: [],
                        }
                    }
                ]), activeDocumentIndex: documents.length, activeType: {
                    name: "Не определён",
                    id: "NOT_EXIST",
                    extended: false,
                    foreign: null,
                    visibleOperatorHelpBadges: [],
                }, activePages: [], activePageIndex: -1 });
            break;
        }
        case PageSplitterActionType.CHANGE_ACTIVE_PAGE: {
            result = __assign({}, state, { activePageIndex: action.payload });
            break;
        }
        case PageSplitterActionType.CHANGE_FREE_PAGE: {
            result = __assign({}, state, { freePageIndex: action.payload });
            break;
        }
        case PageSplitterActionType.ROTATE_ACTIVE_PAGE: {
            var activePages = state.activePages.map(function (page, index) {
                var result = page;
                if (index == state.activePageIndex) {
                    result = __assign({}, page, { rotationAngle: action.payload });
                }
                return result;
            });
            result = __assign({}, state, { activePages: activePages });
            break;
        }
        case PageSplitterActionType.ROTATE_FREE_PAGE: {
            var freePages = state.freePages.map(function (page, index) {
                var result = page;
                if (index == state.freePageIndex) {
                    result = __assign({}, page, { rotationAngle: action.payload });
                }
                return result;
            });
            result = __assign({}, state, { freePages: freePages });
            break;
        }
        case PageSplitterActionType.TAKE_PAGE: {
            result = __assign({}, state);
            if (state.freePages.length) {
                var freePages = state.freePages.filter(function (_, index) { return index !== state.freePageIndex; }).slice();
                var activePages = state.activePages.slice(0, state.activePageIndex + 1).concat([
                    state.freePages[state.freePageIndex]
                ], state.activePages.slice(state.activePageIndex + 1, state.activePages.length));
                result = __assign({}, state, { freePages: freePages,
                    activePages: activePages, freePageIndex: Math.min(state.freePageIndex, freePages.length - 1), activePageIndex: state.activePageIndex + 1 });
            }
            break;
        }
        case PageSplitterActionType.TAKE_ALL_PAGES: {
            var activePages = state.activePages.slice(0, state.activePageIndex + 1).concat(state.freePages, state.activePages.slice(state.activePageIndex + 1, state.activePages.length));
            result = __assign({}, state, { freePages: [], activePages: activePages, activePageIndex: activePages.length - 1 });
            break;
        }
        case PageSplitterActionType.REMOVE_PAGE: {
            result = __assign({}, state);
            if (state.activePages.length) {
                var freePages = state.freePages.concat([
                    state.activePages[state.activePageIndex]
                ]).sort(function (a, b) { return a.number - b.number; });
                var activePages = state.activePages
                    .filter(function (_, index) { return index !== state.activePageIndex; });
                var activePageIndex = state.activePageIndex - 1;
                if (activePages.length && activePageIndex < 0) {
                    activePageIndex = 0;
                }
                var freePageIndex = Math.max(freePages.indexOf(state.freePages[state.freePageIndex]), 0);
                result = __assign({}, state, { freePages: freePages,
                    activePages: activePages,
                    activePageIndex: activePageIndex,
                    freePageIndex: freePageIndex });
            }
            break;
        }
        case PageSplitterActionType.REMOVE_ALL_PAGES: {
            var freePages = state.freePages.concat(state.activePages).sort(function (a, b) { return a.number - b.number; });
            result = __assign({}, state, { freePages: freePages, activePages: [], freePageIndex: 0, activePageIndex: -1 });
            break;
        }
        case PageSplitterActionType.SET_TYPE: {
            result = __assign({}, state, { activeType: action.payload });
            break;
        }
        case PageSplitterActionType.SELECT_DOCUMENT: {
            var documents = updateDocuments(state);
            result = __assign({}, state, { documents: documents, activeDocumentIndex: action.payload, activeType: documents[action.payload].type, activePages: documents[action.payload].pages, activePageIndex: documents[action.payload].pages.length - 1 });
            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS: {
            var documents = updateDocuments(state);
            result = __assign({}, state, { documents: documents, sending: true, sent: false, error: null });
            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS_SUCCESS: {
            result = __assign({}, state, { sending: false, sent: true, error: null });
            break;
        }
        case PageSplitterActionType.SEND_DOCUMENTS_FAIL: {
            result = __assign({}, state, { sending: false, sent: false, error: action.payload });
            break;
        }
        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS: {
            var documents = updateDocuments(state);
            result = __assign({}, state, { documents: documents, sending: true, sent: false, error: null });
            break;
        }
        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_SUCCESS: {
            result = __assign({}, state, { sending: false, sent: true, error: null });
            break;
        }
        case PageSplitterActionType.SEND_AND_STOP_DOCUMENTS_FAIL: {
            result = __assign({}, state, { sending: false, sent: false, error: action.payload });
            break;
        }
    }
    return result;
}
/**
 * Функция создания состояния расклейщика страниц для документа из очереди.
 *
 * @param document Документ из очереди.
 *
 * @return Состояние расклейщика страниц.
 */
function prepareDocument(document) {
    var result = new PageSplitterState();
    if (document) {
        var pages = document.originalData.pages.map(function (page) {
            var id = document.recognitionResult.pages.filter(function (p) { return page.number === p.number; })[0].id;
            var splitterPage = {
                number: page.number,
                rotationAngle: page.rotationAngle,
                url: "/api/v1/documents/" + document.id + "/pages/" + id,
                imageFileId: id,
            };
            return splitterPage;
        });
        result = __assign({}, result, { queueDocumentId: document.id, freePages: pages });
    }
    return result;
}
/**
 * Функция создания состояния расклейщика страниц для документа из очереди по имеющемуся плану расклейки.
 *
 * Возвращает состояние, в котором все документы уже расклеены по заданному плану.
 *
 * @param document Документ из очереди.
 * @param documentTypes Типы документов, хранящиеся в state приложения.
 *
 * @return Состояние расклейщика страниц.
 */
function prepareSplitting(document, documentTypes) {
    var result = new PageSplitterState();
    if (document) {
        var documents_1 = document.reviewedOperatorSplitting.splitPages.documents;
        result = __assign({}, result, { queueDocumentId: document.id, documents: documents_1.map(function (doc) { return ({
                id: doc.id,
                pages: doc.pages.map(function (page) { return (__assign({}, page, { url: "/api/v1/documents/" + document.id + "/pages/" + page.imageFileId })); }),
                type: documentTypes.find(function (type) { return type.id === doc.type; }),
            }); }), freePages: [], activeDocumentIndex: documents_1[0].id, activeType: documentTypes.find(function (type) { return type.id === documents_1[0].type; }), activePages: documents_1[0].pages
                .map(function (page) { return (__assign({}, page, { url: "/api/v1/documents/" + document.id + "/pages/" + page.imageFileId })); }), activePageIndex: 0, freePageIndex: -1 });
    }
    return result;
}
/**
 * Обновление документов до актуального статуса расклейки.
 *
 * @param state Текущее состояние.
 *
 * @return Новый массив документов с актуальными данными.
 */
function updateDocuments(state) {
    return state.documents.slice(0, state.activeDocumentIndex).concat([
        {
            id: state.activeDocumentIndex,
            pages: state.activePages,
            type: state.activeType
        }
    ], state.documents.slice(state.activeDocumentIndex + 1, state.documents.length));
}
