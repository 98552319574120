/**
 * Типы события подписки веб сокета.
 */
export enum WebsocketEventType {
    //region Enum values

    /**
     * Событие обработки документа.
     */
    PROCESS_DOCUMENT = "PROCESS_DOCUMENT",

    /**
     * Событие поддержания соединения по веб сокету.
     */
    KEEP_ALIVE = "KEEP_ALIVE",

    /**
     * Событие ошибки по веб сокету.
     */
    ERROR = "ERROR",

    /**
     * Событие снятия документа с оператора из-за превышения допустимого времени бездействия.
     */
    INACTIVE_OPERATOR_RELEASE_DOCUMENT = "INACTIVE_OPERATOR_RELEASE_DOCUMENT",

    //endregion
}